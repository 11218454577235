import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ShareLink from 'react-facebook-share-link';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Competitor from "./Competitor/Competitor";
import Header from "./Header";

const Details = (inner) => {

    const { id } = useParams()
    const [title, getTitle] = useState([])
    const [votes, getVotes] = useState([])
    const [image, getImage] = useState([])
 
    useEffect(()=>{
        fetchUser();
        document.title = title;
    })

    const fetchUser = async () => {
        fetch(`${process.env.REACT_APP_API_URL + '/api/users/'}${id}`)
            .then((response) => response.json())
            .then((responeData) => {
                getTitle(responeData.name)
                getVotes(responeData.votes)
                getImage(responeData.image)
            });
    }

    const userVote = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        const userId = e.target.getAttribute('data-id');
        formData.append('id', userId)
        
        await axios.post(`${process.env.REACT_APP_API_URL + '/api/vote'}`, formData).then(({ data }) => {
            if (data.status == 1) {
                Swal.fire({
                    icon: "success",
                    text: data.message
                })
                getVotes(data.votes);
            } else {
                Swal.fire({
                    icon: "error",
                    text: data.message
                })
            }
            
        })
    }
    
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="og:title" content={title}/>
                <meta name="og:description" content={title}/>
                <meta property="og:image" content={image ? process.env.REACT_APP_API_URL + "/storage/uploads/"+image : process.env.REACT_APP_BASE_URL + '/assets/images/demo.jpg'}/>
            </Helmet>
            <Header pagename="inner"/>
            <section className="details-wrap inner">
                <div className="container-fluid content_box">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={image ? process.env.REACT_APP_API_URL + "/storage/uploads/"+image : process.env.REACT_APP_BASE_URL + '/assets/images/demo.jpg'}  alt={title} />
                        </div>
                        <div className="col-md-6">
                            <div className="vote_area">
                                <span className="flag"><i className="fa fa-heart-o" aria-hidden="true"></i><span className="vote-nr vote-nr-id">{ (votes > 0 ) ? votes: 0 }</span></span>
                                <span className="friends">  </span>
                            </div>
                            <h5>{title}</h5>
                            <div className="pop-wrap">
                                <div className="pop-btn">
                                <ShareLink link={`${process.env.REACT_APP_BASE_URL + '/details/' + id}`} title={title}>
                                    {link => (
                                        <a href={link} className="btn dalintis fb-share-button details">
                                            Dalintis <i className="fa fa-facebook" aria-hidden="true"></i>            
                                        </a>
                                    )}
                                </ShareLink>  
                                <button type="button" data-id={id} onClick={userVote} className="btn balsuoti vote-btn">Balsuoti<i className="fa fa-heart" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Competitor title = "Kitų dalyvių patiekalai" />
        </div>
    )
}

export default Details
