import React, { useEffect } from 'react';
import Competitor from "./Competitor/Competitor";
import Form from "./Form/Form";
import Header from "./Header";
import Hero from "./Hero/Hero";

const Home = (home) => {

  useEffect(() => {
      document.title = 'Home page';
  });

  return (
    <div>
      <Header pagename="home"/>
      <Hero />
      <Form />
      <Competitor title = "Patiekalai" />
    </div>
  )
}

export default Home
