import React from 'react';
import { NavLink } from "react-router-dom";

const Hero = () => {
    return (
        <section className="hero_part">
            <div className="container-fluid">
                <div className="row">
                    <h3>Įkelk savo karantino patiekalo  nuotrauką ir laimėk įsteigtus prizus!</h3>
                    <ul>
                    <li> Kas savaitę gali laimėti WOLT 30 € kuponą;</li>
                    <li> Pagrindinis prizas - iPhone 11 64 GB</li>
                    </ul>
                    <NavLink to="prizai" className="btn btn-primary dalyvauti">Dalyvauti</NavLink>
                </div>
            </div>    
        </section>
    )
}


export default Hero;