import axios from 'axios';
import React, { Component } from 'react';
import ShareLink from 'react-facebook-share-link';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
export default class Competitor extends Component {
    constructor(props,users) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            users: [],
            userList:[],
            offset: 0,
            perPage: 5,
            currentPage: 0
        };
    }

    receivedData() {
        axios
            .get(`${process.env.REACT_APP_API_URL + '/api/userslist/1'}`)
            .then(res => {
                const data = res.data;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                this.setState({
                    userList: slice,
                    users: data,
                    pages: Math.ceil(data.length / this.state.perPage)
                })
            });
    }

    componentDidMount() {
        this.receivedData();  
    }

    userVote = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        const userId = e.target.getAttribute('data-id');
        const userLocation = e.target.getAttribute('data-location');
        formData.append('id', userId)
        
        await axios.post(`${process.env.REACT_APP_API_URL + '/api/vote'}`, formData).then(({ data }) => {
            if (data.status == 1) {
                Swal.fire({
                    icon: "success",
                    text: data.message
                })
                let users = [...this.state.userList];

                let user = { ...users[userLocation] };
                
                user.votes = data.votes;
                users[userLocation] = user;
                this.setState({
                    userList: users,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    text: data.message
                })
            }
            
            
        })
    
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
                this.receivedData();
        });

    };
    
    render() {
        const { users, page, perPage, pages,userList } = this.state;
        let ausers = userList;
        return (
            <section className="tab_area" id="tab_area">
                <div className="tab-content">
                    <div id="marguciu">
                        <div className="container-fluid">
                            <div className="row">
                                <h3 className="text-center col-md-10">{this.props.title}</h3>
                                <div className="col-md-2">
                                    <select className="form-control sorting">
                                    <option>Rūšiavimas</option>
                                    </select>
                                </div>
                                <div className="col-md-12 col-xs-12 p-l-n p-r-n">
                                   <div className="col-md-12 col-xs-12 p-l-n p-r-n">
                                        {
                                            ausers.length > 0 && (
                                                ausers.map((row, key) => (
                                                    <div className="col-sm-3 col-md-3 col-lg-3" key={key}>
                                                        <div className="pop-box">
                                                            <div className="top_area">
                                                                <span className="flag">
                                                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                                    <span className={`vote-nr vote-nr-${row.id}`}>{ (row.votes > 0 ) ? row.votes: 0 }</span>
                                                                </span>
                                                                <span className="friends">
                                                                        
                                                                </span>
                                                            </div>
                                                            {row.image
                                                                ? <Link to={`/details/${row.id}`} className="competitorBox" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL + '/storage/uploads/' + row.image})` }}></Link>
                                                                : <Link to={`/details/${row.id}`} className="competitorBox" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL + '/assets/images/demo.jpg'})` }}></Link>
                                                            }
                                                            <div className="pop-wrap">
                                                            <h3> {row.name} </h3>
                                                                <div className="pop-btn">
                                                                    
                                                                <ShareLink link={`${process.env.REACT_APP_BASE_URL + '/details/' + row.id}`} title={row.name}>
                                                                    {link => (
                                                                        <a href={link} className="btn dalintis fb-share-button">
                                                                            Dalintis <i className="fa fa-facebook" aria-hidden="true"></i>            
                                                                        </a>
                                                                    )}
                                                                </ShareLink>
                                                                    
                                                                <button
                                                                    type="button"
                                                                    data-id={row.id}
                                                                    data-location={key}
                                                                    className="btn balsuoti vote-btn"
                                                                    onClick={this.userVote}
                                                                >
                                                                    Balsuoti
                                                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                                                </button>
                                                                
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                    { ausers.length > 0 &&    
                                    <div className="col-md-12">
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
