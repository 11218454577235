import React from 'react';
import { NavLink } from "react-router-dom";
import { Link } from 'react-scroll';

const Header = (props) => {
    function menu() {
        var x = document.getElementById("navbar-nav");
        if (x.style.display === "block") {
            x.style.display = "none";
            document.getElementById("menuImage").src = "/assets/images/menuImg.png" 
        } else {
            x.style.display = "block";
            document.getElementById("menuImage").src = "/assets/images/close.png" 
        }
    }
    
    return (
        <header className={props.pagename}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 logo_area">
                        <NavLink className="logo_name" to="/">Logo</NavLink>
                        <button className="icon hidden-sm hidden-md hidden-lg hidden-xl menu_icon" onClick={menu}>
                           <img
                                src={process.env.REACT_APP_BASE_URL + "/assets/images/menuImg.png" }
                                alt="menuImage"
                                className="menuImage"
                                id="menuImage"
                            />
                        </button>
                    </div>
                    <div className="col-md-8 col-xs-12">
                    <ul className="nav navbar-nav home" id="navbar-nav">
                        <li><Link to="/prize">Prizai</Link></li>
                        <li><Link activeClass="active" to="marguciu" spy={true} smooth={true}>Nuotraukos</Link></li>
                        <li><Link to="/details">Laimėtojai</Link></li>
                        <li><NavLink to="/taisykles">Taisyklės</NavLink></li>
                        <li><Link to="dalyvauti" spy={true} smooth={true} className="btn btn-primary menu-dalyvauti">Dalyvauti</Link></li>
                    </ul>
                    </div>
                </div>
            </div>  
        </header>
    )
}
 export default Header