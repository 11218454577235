import axios from 'axios';
import React, { useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Form = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [photo_name, setPhotoName] = useState("")
    const [image, setImage] = useState()
    const [term, setTerm] = useState()
    const [validationError,setValidationError] = useState({})

    const changeHandler = (event) => {
        setImage(event.target.files[0]);
    };

    const handleChecked = (event) => {
        setTerm(event.target.checked);
    };

    const userRegistration = async (e) => {
        e.preventDefault();

        const formData = new FormData()

        formData.append('name', name)
        formData.append('email', email)
        formData.append('photo_name', photo_name)
        formData.append('image', image)
        formData.append('project_id', 1)
        
        await axios.post(`${process.env.REACT_APP_API_URL + '/api/users'}`, formData).then(({ data }) => {
            Swal.fire({
                icon:"success",
                text:data.message
            }).then(() => {
                window.location.reload();
            })
            navigate("/")
                setValidationError('');
            }).catch(({response})=>{
            if(response.status===422){
                setValidationError(response.data.errors)
            }else{
                Swal.fire({
                    text:response.data.message,
                    icon:"error"
                })
            }
        })
    }
    return (
        <section className="apie_konkursa">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12" id="dalyvauti">
                        <form className="registration_form" id="registration" onSubmit={userRegistration}>
                            {
                                Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-md-12 error_area">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {
                                                        Object.entries(validationError).map(([key, value])=>(
                                                            <li key={key}>{value}</li>   
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <div className="col-md-3">
                                    <input type="text" className="form-control" placeholder="Jūsų vardas ir pavardė" id="name" name="name" onChange={(event)=>{ setName(event.target.value)}} />
                                </div>
                                <div className="col-md-3">
                                    <input type="email" className="form-control" placeholder="El. paštas" id="email" name="email" onChange={(event)=>{ setEmail(event.target.value)}} />
                                </div>
                                <div className="col-md-3">
                                    <input type="text" className="form-control" placeholder="Patiekalo pavadinimas" id="photo_name" name="photo_name" onChange={(event)=>{ setPhotoName(event.target.value)}} />
                                </div>
                                <div className="col-md-3">
                                    <label className="images_label" htmlFor="image">
                                        <div className="col-md-10 col-xs-10 p-l-n" id="file_name"> Patiekalo nuotrauka </div>
                                        <div className="col-md-2 col-xs-2"><img src={process.env.REACT_APP_BASE_URL + "/assets/images/upload.png" } className="upload" alt="upload " /></div>
                                    </label>
                                    <div className="file-dummy avatar-view">
                                        <input type="file" name="image" id="image" multiple="multiple" onChange={changeHandler} />
                                    </div>
                                </div>
                            </div>     
                            <div className="form-group">
                                <div className="col-md-5">
                                <label className="main_check">Su <NavLink to="/taisykles">taisyklėmis </NavLink>susipažinau
                                    <input type="checkbox" name="terms" id="checkbox_id" onChange={handleChecked} />
                                    <span className="checkmark"/>
                                </label>
                                </div>
                                <div className="col-md-7">
                                    <button type="submit" className="btn btn-submit">Toliau</button>
                                </div>
                            </div>
                          </div>   
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Form
