import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import Header from "./Header";
function About() {

  useEffect(() => {
    document.title = 'TAISYKLĖS';
  });
    
  return (
    <div className="full_body">
      <Helmet>
          <title>TAISYKLĖS</title>
          <meta name="og:title" content="Meškučių dirbtuvės"/>
          <meta name="og:description" content="Sukurk savo dovaną seneliams"/>
          <meta property="og:image" content="https://meskuciai.delfi.lt/images/-CGDaeJ-.jpeg"/>
      </Helmet>
      <Header pagename="rule"/>
        <div className="rules">
          <div className="container">
            <div className="row align-items-center my-5">
              <div className="col-lg-12">
                <h1 className="font-weight-light text-center">TAISYKLĖS</h1>
                <ol>
                    <li>Projekto trukmė:&nbsp; <strong>2022 06 15 – 2022 07 18 d.</strong>
                    </li>
                    <li>Projekto organizatorius: <strong>UAB Palink.</strong>
                    </li>
                    <li>Projekto esmė: dalyviai kviečiami dalintis savo grilio patiekalų nuotraukomis. Iš daugiausiai balsų surinkusių 10 dalyvių atsitiktine tvarka išrenkamas projekto laimėtojas. </li>
                    <li> Projekto prizas: <strong>100 eurų vertės IKI dovanų kortelė.</strong>&nbsp; </li>
                    <li>Prizai laimėtojams bus išsiųsti paštu registracijos formoje nurodytu adresu.&nbsp;</li>
                    <li>Projektoi dalyviai, pildydami registracijos formą, sutinka, kad dalyvavimo konkurse tikslais UAB DELFI (įmonės kodas 125483974, adresas Gynėjų g. 16, 01109, Vilnius.) rinktų ir tvarkytų jų asmens duomenis (vardą, pavardę, adresą, telefono numerį, el. pašto adresą). Dalyviai supranta, kad aukščiau išvardytų asmens duomenų pateikimas ir sutikimas su jų tvarkymu yra būtina dalyvavimo konkurse sąlyga. Dalyviai turi teisę bet kuriuo metu susipažinti su tvarkomais jų asmens duomenimis ir reikalauti juos ištaisyti ar sunaikinti. Dalyviui pareikalavus sunaikinti tvarkomus jo asmens duomenis, dalyvis praranda galimybę toliau dalyvauti konkurse.</li>
                    <li>Dalyviai sutinka laikytis šių taisyklių ir organizatorių sprendimų.</li>
                    <li>Prizų laimėtojai sutinka ir suteikia teisę UAB DELFI bei jos nuožiūra pasirinktiems asmenims viešai skelbti jų, kaip prizų laimėtojų vardus ir pavardes. Informacija gali būti viešai skelbiama internete, spaudoje ir kitose viešojo informavimo priemonėse, kuriose tai galima daryti pagal Lietuvos Respublikos įstatymus.</li>
                    <li>Papildoma informacija apie konkursą teikiama el. paštu: <strong>&nbsp;konkursas@delfi.lt</strong>
                    </li>
                    <li>Konkurso organizatoriai pasilieka teisę keisti konkurso taisykles iš anksto informavus apie tai viešai.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default About;
