import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Details from "./components/Details";
import Home from "./components/Home";
import Taisykles from "./components/Taisykles";
import "./index.css";
import * as serviceWorker from "./serviceWorker";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>
      <Route path="/details/:id" element={<Details />} />
      <Route exact path='/' element={<Home />} />
      <Route exact path='/taisykles' element={<Taisykles />} />
      <Route exact path='/about' element={<About />} />
    </Routes>
  </Router>
);

serviceWorker.unregister();
